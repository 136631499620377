<template>
	<div>
			<ProgressSpinner style="width: 50px; height: 50px" strokeWidth="4" fill="var(--red-600)"
					animationDuration="1s" />
	</div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "loaderRing",
  setup() {},
  props: {
  },
  watch: {
  },
  computed: {
  },
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
  },
  methods: {
  },
});
</script>

<style lang="scss" scoped>
</style>